import React from "react";
import {Row, Col} from "react-bootstrap";

function Notifications({overLimit, disabled, activeRenewal}) {
  if ((activeRenewal || {}).status === 6) {
    return (<Row> 
      <Col>
        <div className="vw-paper vw-notification blue">
          {"This account is currently being provisioned, we will keep you up to date of the status of your account."}
        </div>
      </Col>
    </Row>);
  }

  return (<div>
    {overLimit
      ? (<Row> 
        <Col>
          <div className="vw-paper vw-notification warning">
            {"You have reached the data limit associated with your plan."}
          </div>
        </Col>
      </Row>)
      : undefined
    }
    {!activeRenewal
      ? (<Row> 
        <Col>
          <div className="vw-paper vw-notification error">
            {"You do not have an active plan renewal, your internet will be disconnected at the end of your current plan."}
          </div>
        </Col>
      </Row>)
      : undefined
    }
    {(activeRenewal || {}).status === 3
      ? (<Row> 
        <Col>
          <div className="vw-paper vw-notification warning">
            {"Your Plan is scheduled to be terminated, please update your plan information to rectify this potential issue"}
          </div>
        </Col>
      </Row>)
      : undefined
    }
    {disabled
      ? (<Row> 
        <Col>
          <div className="vw-paper vw-notification error">
            {"Your Plan has been disabled, please update your plan information to rectify this issue"}
          </div>
        </Col>
      </Row>)
      : undefined
    }
  </div>)
}
export default Notifications;

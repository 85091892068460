import React, { useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";
import {getOperationName} from "apollo-utilities";

import TextField from "../../bootstrap/input";
import Modal from "../../bootstrap/modal";
import {updateDeviceMutation} from "../../logic/device";
import { getCurrentUserQuery } from "../../logic/user";

export default function ChangeDetails(props) {
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
  });

  const { onClose, device } = props;
  const formRef = useRef(null);
  const [updateDevice] = useMutation(updateDeviceMutation);

  async function handleSubmit({hostname, macAddress}) {
    await setState((prevState) => ({ ...prevState, processing: true }));

    try {
      await updateDevice({
        variables: {
          hostname,
          id: device.id
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      })

      await setState((prevState) => ({ ...prevState, processing: false }));
      return onClose();
    } catch (err) {
      return setState((prevState) => ({ ...prevState, processing: false, error: err }));
    }
  }

  return (
    <Modal
      title={"Add Device"}
      show
      onClose={async() => onClose()}
      footer={
        <Container>
          <Row>
            <Col xs="auto ml-auto">
              <Button
                className="vw-button black-text"
                type="button"
                colour="light"
                disabled={state.processing}
                onClick={(e) => {
                  e.preventDefault();
                  return onClose();
                }}
              >
                {"Cancel"}
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                variant="black"
                className="vw-button black"
                type="submit"
                disabled={state.processing}
                onClick={() => formRef.current.submit()}
              >
                {"Save"}
              </Button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Formsy ref={formRef} onValidSubmit={handleSubmit}>
        {state.error && <Row>
          <Col>
            <div className="alert alert-danger">
              {state.error}
            </div>
          </Col>
        </Row>}
        <Row className="field-row mb-2">
          <Col>
            <TextField
              inline
              label={"Mac Address"}
              placeholder={"Mac Address"}
              defaultValue={device?.macAddress}
              name="macAddress"
              disabled
              required
            />
          </Col>
        </Row>
        <Row className="field-row mb-2">
          <Col>
            <TextField
              inline
              label={"Name"}
              name="hostname"
              defaultValue={device?.hostname}
              placeholder={"Name"}
              required
            />
          </Col>
        </Row>
      </Formsy>
    </Modal>
  );
}
